<template>
  <div v-if="pageMeta" class="tw-my-6 tw-flex tw-justify-between">
    <div>
      <cl-breadcrumbs
        :pageMeta="pageMeta"
        :routePath="$route.path"
        class="tw-mb-4"
        @on-click="goTo"
      />
      <cl-heading v-if="pageMeta.title" size="h1">
        {{ pageMeta.title }}
      </cl-heading>
      <span v-if="pageMeta.subTitle">{{ pageMeta.subTitle[0] }}</span>
    </div>

    <cl-header-dropdown data-test-id="page-header-dropdown">
      <template v-slot:title>
        <p class="tw-mb-0 tw-text-sm">{{ $t("Itarian Account") }}</p>
        <p class="tw-mb-0 tw-font-bold">{{ user.accountname || "" }}</p>
        <p class="tw-mb-0 tw-text-sm">{{ user.email || "" }}</p>
      </template>
      <template v-slot:products>
        <cl-header-dropdown-product
          v-for="product in productLinks"
          :label="product.active ? '' : $t('Try Now')"
          :link="product.link"
          :active="product.active"
          :key="product.id"
          :data-test-id="`user-menu-${product.title.toLowerCase()}titan`"
        >
          <div class="tw-flex tw-gap-4">
            <img
              class="tw-relative tw-h-7 tw-w-7"
              :src="product.icon"
              :alt="product.description"
            />
            <div>
              <div class="tw-text-primary-800">
                {{ `${product.title}Titan` }}
              </div>
              <div class="tw-text-primary-800 tw-text-sm tw-font-bold">
                {{ product.description }}
              </div>
            </div>
          </div>
        </cl-header-dropdown-product>
      </template>
      <template v-slot:appearance>
        <div
          class="tw-relative tw-flex tw-items-center tw-justify-between"
          @click.stop
        >
          <span>Appearance:</span>
          <cl-form-radio-group
            button
            :options="themeOptions"
            :selectedValue="userPreferredTheme"
            @update:selected="(selected) => setUserPreferredTheme(selected)"
          />
        </div>
      </template>
      <template v-slot:links>
        <div v-for="(userLink, idx) in userLinks" :key="idx">
          <router-link
            v-if="userLink.isInternal"
            :to="userLink.link"
            class="tw-no-underline"
            :data-test-id="`user-menu-${userLink.title.toLowerCase()}`"
            target="_blank"
          >
            <cl-header-dropdown-link
              :link="userLink.link"
              :label="userLink.title"
              target="_blank"
            >
              <component :is="userLink.icon" width="18px" height="18px" />
            </cl-header-dropdown-link>
          </router-link>
          <cl-header-dropdown-link
            v-else
            :link="userLink.link"
            target="_blank"
            :label="userLink.title"
            :data-test-id="`user-menu-${userLink.title.toLowerCase()}`"
          >
            <component :is="userLink.icon" width="18px" height="18px" />
          </cl-header-dropdown-link>
        </div>
        <router-link :to="{ name: 'logout' }" class="tw-no-underline">
          <cl-header-dropdown-link
            :link="'/logout'"
            :label="$t('Logout')"
            data-test-id="user-menu-logout"
          >
            <cl-icon-tabler-logout :width="'18'" :height="'18'" />
          </cl-header-dropdown-link>
        </router-link>
      </template>
    </cl-header-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { useTheme } from "@/composables/useTheme";
import { THEME } from "@/constants/theme";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    userLinks: {
      type: Array,
      required: true,
    },
    productLinks: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  setup() {
    const { userPreferredTheme, setUserPreferredTheme } = useTheme();

    return {
      userPreferredTheme,
      setUserPreferredTheme,
      themeOptions: [
        { label: "Dark", value: THEME.DARK },
        { label: "Light", value: THEME.LIGHT },
        { label: "System", value: THEME.SYSTEM },
      ],
    };
  },
  computed: {
    ...mapGetters("pageMeta", ["pageMeta"]),
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
  },
};
</script>
