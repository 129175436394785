export const PRODUCTS = Object.freeze({
  spamIcon: "assets/branding/Email Protection.svg",
  spamDescription: "Email Anti-Spam Solution",

  webIcon: "assets/branding/WebTitan.svg",
  webDescription: "DNS Filtering",

  safeIcon: "assets/branding/SafeTitan.png",
  safeDescription: "Security Awareness Training",

  arcIcon: "assets/branding/ArcTitan.svg",
  arcDescription: "Security Archiving Solution",

  phishIcon: "assets/branding/PhishTitan.svg",
  phishDescription: "Phishing Protection",

  userLinksTitle: "Security",
});

export const SPAM_CONFIG = Object.freeze({
  title: "Spam",
  link: "#",
  active: true,
  versionName: " - Skellig",
});

export const WEB_CONFIG = Object.freeze({
  title: "Web",
  link: "https://www.titanhq.com/webtitan-customer-signup?from_product=spamtitan&to_product=webtitan",
  active: false,
});

export const SAFE_CONFIG = Object.freeze({
  title: "Safe",
  link: "https://www.titanhq.com/safetitan-customer-signup?from_product=spamtitan&to_product=safetitan",
  active: false,
});

export const ARC_CONFIG = Object.freeze({
  title: "Arc",
  link: "https://www.titanhq.com/arctitan-customer-signup?from_product=spamtitan&to_product=arctitan",
  active: false,
});

export const PHISH_CONFIG = Object.freeze({
  title: "Phish",
  link: "https://www.titanhq.com/phishtitan-customer-signup?from_product=spamtitan&to_product=phishtitan",
  active: false,
});

export const USER_LINKS = Object.freeze({
  link: "/profile/security",
  icon: "cl-icon-tabler-settings",
  isInternal: true,
});

export const HELPDESK = Object.freeze({
  link: "https://support.xcitium.com/",
});

export const ARC_CONTENT = Object.freeze({
  line1:
    "Cloud based email archiving - reduces the cost and complexity of archiving emails and satisfy discovery requests",
  line2: "Data loss prevention and regulatory and legal compliance",
  line3:
    "Significantly enhances Office 365 email search and storage functionality",
});

export const SAFE_CONTENT = Object.freeze({
  line1: "Security Awareness Training (SAT) powering human layer protection",
  line2:
    "Delivery contextual training in real-time. ONLY available from SafeTitan.",
  line3: "Unlimited phishing simulations",
});

export const WEB_CONTENT = Object.freeze({
  line1: "Advanced threat protection, DNS security and content filtering",
  line2: " Proactive protection from malicious web threats and attacks",
  line3: "Scalable & Fast",
});

export const PHISH_CONTENT = Object.freeze({
  line1:
    "Cloud based, Al driven solution providing advanced phishing protection to organizations using Microsoft 365",
  line2: "Quick deployment",
  line3: "Post delivery remediation",
  line4: "Itarian Add-in",
});

export const PRODUCTS_IMG = Object.freeze({
  SAFE: "assets/branding/safetitan-square.png",
  WEB: "assets/branding/webtitan-square.png",
  ARC: "assets/branding/arctitan-square.png",
  PHISH: "assets/branding/phishtitan-square.png",
});

export default {};
